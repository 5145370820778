/* eslint no-console:0 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import axios from 'axios';
import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';

Vue.use(TurbolinksAdapter);

let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json';

document.addEventListener('turbolinks:load', () => {
  let element = document.getElementById('onboarding-profiles-sharing')
  if (element != null) {
    const app = new Vue({
      el: element,
      data: {
        loading: true,
        selected: [],
        profiles: []
      },
      mounted: function() {
        axios
          .get('connect/search/all.json')
          .then(
              response => {
                this.loading = false
                this.profiles = response.data.profiles
                this.selected = response.data.profiles_selected
              });
      },
      computed: {
        selectAll: {
          get: function () {
            return this.profiles ? this.selected.length == this.profiles.length : false;
          },
          set: function (value) {
            var selected = [];

            if (value) {
              this.profiles.forEach(function (profile) {
                selected.push(profile.id);
              });
            }
            this.selected = selected;
          }
        },
        totalProfiles: function () {
          return this.selected.length;
        }
      },
      methods: {
        toggleProfile(profile, e) {
          if (e.target.checked) {
            axios
              .post('connect/profiles/' + profile.account_type, {
                id: e.target.id
              })
              .then(function (response) {
                console.log(response);
              });
          } else {
            axios
              .delete('connect/profiles/' + e.target.id)
              .then(function (response) {
                console.log(response);
              });
          };
        }
      }
    })
  }
})
